<template>
  <div class="mcc con_area_bgcolor">
    <div class="mcc_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">Mcct Convention Center</span>
        </h2>
        <div>
          <img
            src="../assets/img/mcc/tree.jpg"
            title="点击查看"
            @click="tz('Mcc_desc')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    tz(name, paramsvalue) {
      $(window).scrollTop(0);
      if (paramsvalue) {
        this.$router.push({
          name: name,
          query: { param: paramsvalue },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style>
.mcc {
  width: 100%;
}
.mcc_con {
  width: 100%;
}
.mcc_con .w11402 {
  /* width: 1140px; */
  margin: 0 auto;
  overflow: hidden;
}
.mcc_con .w11402 > h2 {
  font-size: 1.6em;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #ccc;
  line-height: 0.1em;
  margin: 4rem 0 5rem;
}
.mcc_con h2 > span {
  background: #fafafa;
  /* background: #ffffff; */
  padding: 0 10px;
  box-sizing: border-box;
  /* font-weight: 500; */
}
.mcc_con .w11402 > div {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mcc_con .w11402 > div > img {
  width: 70%;
  height: auto;
  cursor: pointer;
}
</style>